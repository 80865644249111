<template>
    <v-autocomplete
        v-model="name"
        return-object
        :items="names"
        :item-text="itemText"
        v-bind="$attrs"
        :error-messages="errorMessages"
        :loading="loading"
        :search-input.sync="query"
        :label="label"
    />
</template>

<script>
import Artist from "@/models/Artist";
import Venue from "@/models/Venue";


export default {
    name: "NameSelector",
    props: {
        type: {
            type: Object,
            default: () => ({label: 'Artists', endPoint: 'artists'})
        },
        city: {
            type: Object,
            default: () => null,
        },
        value: {
            type: [Number, Object]
        },
        errorMessages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            query: null,
            name: null,
            names: [],
            stageName: null,
        }
    },
    methods: {
        async fetchArtists() {
            this.loading = true

            let query = Artist.custom('artists/public').limit(10);

            if (this.city) {
                query = query.where('city_id', this.city.id)
            }

            if (this.query) {
                query = query.where('name', this.query)
            }

            const names = await query.get();

            if (this.name) {
                names.push(this.name)
            }

            this.names = names
            this.loading = false
        },
        async fetchVenues() {
            this.loading = true

            let query = Venue.custom('venues/public').limit(10)

            if (this.city) {
                query = query.where('city_id', this.city.id)
            }

            if (this.query) {
                query = query.where('name', this.query)
            }

            const names = await query.get();

            if (this.name) {
                names.data.push(this.name)
            }
            this.names = names.data
            this.loading = false
        },
        fetchData() {
            switch (this.type.endPoint) {
                case 'artists':
                    this.fetchArtists();
                    break
                case 'venues':
                    this.fetchVenues();
                    break
            }
        }
    },
    mounted() {
        this.fetchData()
    },
    watch: {
        name(val) {
            if (!val) {
                this.$emit('input', null);
                return
            }

            val = this.$attrs['return-object'] === '' || !!this.$attrs['return-object'] ? val : val.id;

            this.$emit('input', val);
        },

        query() {
            this.fetchData()
        },

        type() {
            this.name = null
            this.fetchData()
        },

        city() {
            this.name = null
            this.fetchData()
        },

        value(val) {
            if (val !== this.name) {
                this.name = val
            }
        }
    },
    computed: {
        label() {
            return `Search for ${this.type.label}`
        },
        itemText() {
            let itemText = ''
            switch (this.type.endPoint) {
                case 'artists':
                    itemText = 'name';
                    break
                case 'venues':
                    itemText = 'name';
                    break
            }
            return itemText
        },
    },
}
</script>

<style scoped>

</style>
