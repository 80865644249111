<template>
    <v-container>
        <v-row class="flex-column flex-sm-row">
            <v-col sm="5" md="3" class="pa-0">
                <v-select
                    v-model="type"
                    return-object
                    :items="types"
                    value="artists"
                    item-text="label"
                    item-value="endPoint"
                    hide-details="auto"
                    dense solo flat
                    hide-selected
                    class="rounded-0"
                    label="Looking for"
                    prepend-inner-icon="mdi-telescope"
                    menu-props="tile, offset-y, open-on-hover"
                ></v-select>
            </v-col>
            <v-col sm="7" md="3" class="pa-0">
                <city-selector
                    v-model="city"
                    return-object
                    dense solo flat
                    clearable
                    hide-details="auto"
                    prepend-inner-icon="mdi-map-marker"
                    class="rounded-0"
                />
            </v-col>
            <v-col class="pa-0">
                <name-selector
                    v-model="name"
                    return-object
                    :type="type"
                    :city="city"
                    dense solo flat
                    clearable
                    hide-details="auto"
                    prepend-inner-icon="mdi-magnify"
                    class="rounded-0"
                />
            </v-col>
            <v-col cols="auto" class="pa-0">
                <v-btn
                    :href=submitLink
                    color="error"
                    tile
                    elevation="0"
                    height="100%"
                    min-height="38px"
                    width="100%"
                >
                    Search
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CitySelector from "@/components/CitySelector";
import NameSelector from "@/components/NameSelector";

export default {
    name: "global-search-form",
    components: {CitySelector, NameSelector},
    data: function () {
        return {
            menu: null,
            type: {label: 'Artists', endPoint: 'artists'},
            city: null,
            name: null,
            submitLink: 'artists',
            types: [
                {label: 'Artists', endPoint: 'artists'},
                {label: 'Venues', endPoint: 'venues'},
            ]
        }
    },
    methods: {
        constructSubmitLink() {
            const queryString = new URLSearchParams({
                cityId: this.city && this.city.id || '',
                name: this.name && this.name[this.nameSlug] || '',
            }).toString();
            this.submitLink = `${this.type.endPoint}?${queryString}`;
        },
    },
    watch: {
        type() {
            this.constructSubmitLink()
        },
        city() {
            this.constructSubmitLink()
        },
        name() {
            this.constructSubmitLink()
        },
    },
    computed: {
        nameSlug() {
            let nameSlug = ''
            switch (this.type.endPoint) {
                case 'artists':
                    nameSlug = 'name';
                    break
                case 'venues':
                    nameSlug = 'name';
                    break
            }
            return nameSlug
        },
    },
}
</script>

<style scoped>

</style>
