<template>
    <front-layout :is-homepage="isHomepage">
        <div v-if="!loading" v-html="html" id="gjsPage"></div>
        <v-container v-else class="page__top-line">
            <v-skeleton-loader  type="list-item-avatar-three-line, image, article" />
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "@/layouts/FrontLayout";
    import Page from "@/models/Page";

    import Vue from 'vue';
    import GlobalSearchForm from "@/components/GlobalSearchForm";
    import vuetify from '@/plugins/vuetify';

    export default {
        name: 'gjs-page-layout',
        components: {FrontLayout},
        data: function () {
            return {
                pageSlug: 'homepage',
                html: '',
                css: '',
                loading: false,
                globalSearchForm: null,
            }
        },
        mounted() {
            this.pageSlug = this.$route.meta.pageSlug
            this.loadPage();
        },
        beforeDestroy() {
            this.removeCustomStyle()
        },
        methods: {
            async loadPage() {
                this.loading = true;
                const page = await Page.custom(`pages/slug/${this.pageSlug}`).first();
                this.html = page.html;
                this.css = page.css;
                this.applyCustomStyle();
                this.loading = false;
                this.startObserver();
            },
            applyCustomStyle() {
                const customStyle = document.createElement('style')
                customStyle.type = 'text/css'
                customStyle.id = `${this.pageSlug}-stylesheet`
                customStyle.textContent = this.css
                document.querySelector('head').append(customStyle)
            },
            removeCustomStyle() {
                const customStyle = document.querySelector(`#${this.pageSlug}-stylesheet`)
                if (customStyle) {
                    customStyle.remove()
                }
            },
            startObserver() {
                const mainWrap = document.querySelector('.v-main__wrap');
                if (!mainWrap) return;

                let observer = new MutationObserver(() => {
                    if (document.querySelector('#gjsPage')) {
                        observer.disconnect();
                        this.createGlobalSearchForm()
                        const signUpSection = document.querySelector('#ib4yx') // Section 'Click here to sign up for EXCLUSIVE Preview NOW'
                        if(signUpSection && this.$auth.user()) {
                            signUpSection.className = 'd-none'
                        }
                    }
                });
                observer.observe(document.querySelector('.v-main__wrap'), {
                    childList: true,
                    subtree: true,
                });
            },
            createGlobalSearchForm() {
                this.globalSearchForm = document.querySelector('#globalSearchForm');
                if (!this.globalSearchForm) return;
                new Vue({
                    el: '#globalSearchForm',
                    render: h => h(GlobalSearchForm),
                    vuetify,
                })
            },
        },
        computed: {
            isHomepage() {
                return (this.pageSlug == 'homepage') ? true : false;
            }
        },
        watch: {
            $route(to) {
                this.removeCustomStyle()
                this.pageSlug = to.meta.pageSlug
                this.loadPage();
            }
        }
    }
</script>
